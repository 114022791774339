import { LoginPage } from './pages/login'
import { NotesPage } from './pages/notes';
import { AuthProvider } from './hooks/useAuth';
import { Routes, Route } from "react-router-dom";
import { ProtectedRoute } from './components/ProtectedRoute';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Footer } from './components/footer';


const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#66b3ff',
    },
  },
});



function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route
            path="/notes"
            element={
              <ProtectedRoute>
                <NotesPage />
              </ProtectedRoute>
            }
          />
        </Routes>
        <Footer />
      </ThemeProvider>
    </AuthProvider>
  );
}
export default App;
