import './login.css'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { Navigate } from "react-router-dom";
import { auth } from '../../firebase'
import { useAuth } from '../../hooks/useAuth';
import { useLocalStorage } from "../../hooks/useLocalStorage";

class LoginPageState {
    username = '';
    password = '';
    helperText = '';
    loading = false;
}

export function LoginPage() {
    const [user, _] = useLocalStorage("user", null);
    const { login } = useAuth();
    const [loginPageState, setLoginPageState] = useState(new LoginPageState());

    if (user) {
        return <Navigate to="/notes" />;
    }
    const handleLogin = async () => {
        try {
            setLoginPageState({ ...loginPageState, loading: true })
            const userCredential = await signInWithEmailAndPassword(auth, loginPageState.username, loginPageState.password);
            const user = userCredential.user;
            setLoginPageState({ ...loginPageState, loading: false })
            await login(user.toJSON())
        } catch (error) {
            setLoginPageState({ ...loginPageState, helperText: error.message, loading: false })
        }
    }
    return (
        <Grid
            container
            spacing={2}
            justifyContent={"center"}
            alignContent={"center"}
            className='outer-grid'
            direction={"column"}
            backgroundColor={"#080e14"}
            color={"#ffffff"}
        >
            <Grid item>
                <Typography variant="h4" align='center'>Login</Typography>
            </Grid>
            <Grid item>
                <TextField
                    label="Username" variant="standard" fullWidth
                    onChange={(e) => setLoginPageState({ ...loginPageState, username: e.target.value })}
                />
            </Grid>
            <Grid item>
                <TextField
                    label="Password" variant="standard" type="password" fullWidth
                    onChange={(e) => setLoginPageState({ ...loginPageState, password: e.target.value })}
                />
            </Grid>
            {
                loginPageState.helperText ?
                    <Grid item>
                        <Alert variant="outlined" severity="error">{loginPageState.helperText}</Alert>
                    </Grid> : null
            }
            <Grid item>
                <LoadingButton
                    variant="outlined" fullWidth
                    onClick={handleLogin}
                    loading={loginPageState.loading}
                    loadingIndicator="Loading…"
                >
                    <span>Login</span>
                </LoadingButton>
            </Grid>
        </Grid>

    )
}

