import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBpmtuwzbSsjHHarsBwRLqQauK4LesErUQ",
    authDomain: "khanumoory.firebaseapp.com",
    projectId: "khanumoory",
    storageBucket: "khanumoory.appspot.com",
    messagingSenderId: "62896652507",
    appId: "1:62896652507:web:e9396b5ab51076dbd48cfe",
    measurementId: "G-N3Z1BLWVNV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);