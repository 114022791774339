import './note.css';
import { useState } from 'react';
import { animated, useSpring } from '@react-spring/web'

class NoteBookState {
    unseenIdxs = [];
    idx1 = -1;
    idx2 = -1;
    n = 0;


    constructor(n) {
        this.n = n;
        this.resetUnseenIdxs();
        this.idx1 = this.unseenIdxs[Math.floor(Math.random() * this.unseenIdxs.length)];
        this.unseenIdxs = this.unseenIdxs.filter((i) => i !== this.idx1);
        this.idx2 = 0
        this.zidx1 = 1;
        this.zidx2 = 0;
    }

    resetUnseenIdxs = () => {
        this.unseenIdxs = Array.from(Array(this.n).keys());
    }

    setNewIdx1 = () => {
        if (this.unseenIdxs.length === 0) {
            this.resetUnseenIdxs();
        }
        this.idx1 = this.unseenIdxs[Math.floor(Math.random() * this.unseenIdxs.length)];
        this.unseenIdxs = this.unseenIdxs.filter((i) => i !== this.idx1);
    }
    setNewIdx2 = () => {
        if (this.unseenIdxs.length === 0) {
            this.resetUnseenIdxs();
        }
        this.idx2 = this.unseenIdxs[Math.floor(Math.random() * this.unseenIdxs.length)];
        this.unseenIdxs = this.unseenIdxs.filter((i) => i !== this.idx2);
    }
}



export function NoteBook(props) {

    let [noteState, setNoteState] = useState(new NoteBookState(props.notes.length));
    const [rotateX1, setRotateX1] = useState(0);
    const [zIndex1, setZIndex1] = useState(1);
    const [springs1, api1] = useSpring(() => ({
        from: {
            rotateX: rotateX1,
            zIndex: zIndex1,
            backgroundColor: '#f9f9f9',
        },
    }))
    const [rotateX2, setRotateX2] = useState(0);
    const [zIndex2, setZIndex2] = useState(0);
    const [springs2, api2] = useSpring(() => ({
        from: {
            rotateX: rotateX2,
            zIndex: zIndex2,
            backgroundColor: '#f9f9f9',
        },
    }))

    const onClickCard1 = () => {

        api1.start({
            from: {
                rotateX: rotateX1,
                zIndex: zIndex1
            },
            to: [
                {
                    rotateX: rotateX1 + 180,
                    zIndex: zIndex1 ^ 1,
                    backgroundColor: '#d4d4d4',
                },
                {
                    rotateX: rotateX1 + 360,
                    zIndex: zIndex1 ^ 1,
                    backgroundColor: '#f9f9f9',
                },
            ],
            config: { duration: 500 }
        })
        api2.start({
            from: {
                zIndex: zIndex2
            },
            to: {
                zIndex: zIndex2 ^ 1
            }
        })
        setRotateX1(rotateX1 + 360);
        setZIndex1(zIndex1 ^ 1);
        setZIndex2(zIndex2 ^ 1);
        noteState.setNewIdx2();
        setNoteState(noteState);

    }
    const onClickCard2 = () => {
        api2.start({
            from: {
                rotateX: rotateX2,
                zIndex: zIndex2
            },
            to: [
                {
                    rotateX: rotateX2 + 180,
                    zIndex: zIndex2 ^ 1,
                    backgroundColor: '#d4d4d4',
                },
                {
                    rotateX: rotateX2 + 360,
                    zIndex: zIndex2 ^ 1,
                    backgroundColor: '#f9f9f9',
                },
            ],
            config: { duration: 500 }
        })
        api1.start({
            from: {
                zIndex: zIndex1
            },
            to: {
                zIndex: zIndex1 ^ 1
            }
        })
        setRotateX2(rotateX2 + 360);
        setZIndex2(zIndex2 ^ 1);
        setZIndex1(zIndex1 ^ 1);
        noteState.setNewIdx1();
        setNoteState(noteState);
    }
    return (
        <>
            <div className='note-hook-1'></div>
            <div className='note-hook-2'></div>
            <animated.div
                className="note-1"
                onClick={onClickCard1}
                style={springs1}
            >
                <div className='note-header'>
                    {'About ' + props.notes[noteState.idx1].to + ',\n\n'}
                </div>
                <div>
                    {props.notes[noteState.idx1].message}
                </div>
            </animated.div >
            <animated.div
                className="note-2"
                onClick={onClickCard2}
                style={springs2}
            >
                <div className='note-header'>
                    {'About ' + props.notes[noteState.idx2].to + ',\n\n'}
                </div>
                <div>
                    {props.notes[noteState.idx2].message}
                </div>

            </animated.div>

        </>

    )
}


